/*
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-03-10 17:03:20
 * @LastEditors: hutian
 * @LastEditTime: 2021-03-10 17:58:22
 */
/**
* api接口统一管理
*/
import { axios } from '@/utils/request'

export const listMhiacFirm = params => axios({
    url: '/api/firm/mhiac/mhiacFirm/list',
    method: 'get',
    params,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const addMhiacFirm = params => axios({
    url: '/api/firm/mhiac/mhiacFirm/add',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const editMhiacFirm = params => axios({
    url: '/api/firm/mhiac/mhiacFirm/edit',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const delMhiacFirm = params => axios({
    url:'/api/firm/mhiac/mhiacFirm/del/' + params,
    method: 'post',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const selectByIdMhiacFirm = params => axios({
    url: '/api/firm/mhiac/mhiacFirm/selectById/' + params,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})
